import { Label } from 'components/ui/Label'
import { useId } from 'hooks/useId'
import React from 'react'
import {
  useFormContext,
  UseFormReturn,
  Controller,
  RegisterOptions,
  get,
} from 'react-hook-form'
import cx from 'classnames'
// import { removeAccents } from 'utils/string'
import { FormInfoMessage, FormErrorInfoMessage } from './components'

export type FormElement<T> = {
  label?: string
  id?: string
  helpText?: React.ReactNode
  containerClassName?: string
  name: string
  isRequired?: boolean
  labelClassName?: string
  isHideError?: boolean
} & T

export type NonNativeOnChange<T> = (
  setValue: UseFormReturn['setValue'],
  field: string,
) => (value: T) => void

export function createFormElement<TFromElement, TNonNativeOnChange = any>(
  Component: React.FC<any>,
  nonNativeOnChange?: NonNativeOnChange<TNonNativeOnChange>,
) {
  return React.forwardRef<
    HTMLInputElement,
    FormElement<TFromElement> & {
      rules?: Omit<
        RegisterOptions,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs'
      >
      labelHelperText?: string
    }
  >((props, forwardedRef) => {
    const {
      label,
      helpText,
      containerClassName,
      name,
      rules,
      id: propId,
      labelHelperText,
      isRequired,
      labelClassName,
      isHideError,
      ...rest
    } = props

    const id = useId(propId)
    const {
      formState: { errors },
      setValue,
    } = useFormContext()

    const isError = Boolean(get(errors, name))

    return (
      <div className={containerClassName}>
        {label && (
          <Label
            htmlFor={id}
            className={cx('mb-1', labelClassName)}
            isRequired={isRequired}
          >
            {label}
          </Label>
        )}
        <Controller
          rules={rules}
          render={(controllerProps) => (
            <Component
              {...controllerProps.field}
              {...rest}
              id={id}
              invalid={isError && !isHideError}
              onChange={
                nonNativeOnChange
                  ? nonNativeOnChange(setValue, name)
                  : controllerProps.field.onChange
              }
              aria-invalid={isError && !isHideError}
              // TODO: correctly merge refs and check for all input components
              ref={forwardedRef}
              label={label}
              checked={controllerProps.field.value}
            />
          )}
          name={name}
        />

        {isError && !isHideError ? (
          <FormErrorInfoMessage id={name}>
            {get(errors, name)?.message}
          </FormErrorInfoMessage>
        ) : null}

        {!isError && helpText ? (
          <FormInfoMessage>{helpText}</FormInfoMessage>
        ) : null}
      </div>
    )
  })
}

export default createFormElement
