import { HTMLAttributes } from 'react'
import cx from 'classnames'
import { Text } from 'components/ui/Text'

export const FormErrorInfoMessage: React.FC<
  HTMLAttributes<HTMLParagraphElement>
> = ({ className, id, ...rest }) => (
  <Text
    as="span"
    textColor="none"
    fontSize="xs"
    data-invalid={id}
    className={cx('text-v2-danger-400 leading-normal mt-2 -pl-4', className)}
    {...rest}
  />
)

export const FormInfoMessage: React.FC<
  HTMLAttributes<HTMLParagraphElement>
> = ({ className, ...rest }) => (
  <Text
    v2TextColor="Gray-Light"
    fontSize="xs"
    className={cx('leading-normal mt-2', className)}
    {...rest}
  />
)
