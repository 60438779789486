import { IconButton } from 'components/ui/IconButton'
import { useRouter } from 'next/router'
import React, { ReactNode } from 'react'
import { WithChildren } from 'types/common'
import { useTranslations } from 'next-intl'
import { Divider } from 'components/ui/Divider'
import { IconLongArrowLeft } from 'components/icons/components/IconLongArrowLeft'
import { Button } from 'components/ui/Button'
import { IconSupport } from 'components/icons/components/IconSupport'

interface Props extends WithChildren {
  footerRender?: React.ReactNode
  onBack?: () => void
  rightExtra?: ReactNode
}

export function AuthLayoutV2({
  children,
  footerRender,
  onBack,
  rightExtra,
}: Props) {
  const t = useTranslations()
  const { push } = useRouter()

  return (
    <div className="absolute sm:container inset-0 bg-white overflow-y-auto overflow-x-hidden flex flex-col justify-between">
      <div className="overflow-auto overflow-x-hidden min-h-[450px] relative px-8">
        {typeof onBack === 'function' && (
          <>
            <div className="relative bg-white w-auto">
              <div className="py-4 h-max flex align-middle justify-between">
                <IconButton
                  type="button"
                  onClick={onBack}
                  Icon={IconLongArrowLeft}
                  label={t('back')}
                  iconClassName="h-7 w-7"
                  className="h-max flex align-middle"
                />
                {rightExtra || (
                  <Button
                    size="sm"
                    variant="outline"
                    className="text-base uppercase"
                    onClick={(e) => {
                      e.stopPropagation()
                      push(`tel:${t('support_tel')}`)
                    }}
                    Icon={IconSupport}
                  >
                    DROPPII {t('support')}
                  </Button>
                )}
              </div>
              <Divider />
            </div>

            <div
              className="w-32 h-36 absolute bg-cover -top-8 -right-4 pointer-events-none"
              style={{ backgroundImage: `url(/img/auth-backdrop.png)` }}
            />
          </>
        )}
        {children}
      </div>
      {footerRender}
    </div>
  )
}
